<template>
    <div>
        <div class="text-center iq-title-box iq-box iq-title-default">
            <h2 class="iq-title">
                Contact Us!
            </h2>
            <p class="iq-title-desc">If you’re interested in learning more about the NCL and Olympic Style Lacrosse, or if your college/university wants to join the league, please fill out the following form. One of our representatives will be in touch soon. </p>
        </div>
        <div class="wrapper">
      <form action="" @submit.prevent="sendEmail" class="wpcf7-form init" novalidate="novalidate" data-status="init">
        <div class="contact-form contact-style">
            <div class="row">
                <input type="hidden" name="to_email" value="" size="40">
                <input type="hidden" name="cc_email" value="" size="40">
                <div class="col-md-12 cfield">
                    <input type="text" name="name" v-model="name" value="" size="40" aria-required="true" aria-invalid="false" placeholder="Your Name">
                </div>
                <div class="col-md-6 cfield">
                    <input type="tel" name="phone" v-model="phone" value="" size="40" maxlength="140" minlength="10" aria-required="true" aria-invalid="false" placeholder="Phone Number (optional)">
                </div>
                <div class="col-md-6 cfield">
                    <input type="email" name="email" v-model="email" value="" size="40" aria-required="true" aria-invalid="false" placeholder="Your Email">
                </div>
                <div class="col-md-12 cfield">
                    <textarea name="message" v-model="message" cols="40" rows="10" aria-required="true" aria-invalid="false" placeholder="Your Message"></textarea>
                </div>
                <div class="col-md-12 cfield">
                <p v-if="errors.length">
                    <b>Please correct the following error(s):</b>
                    <ul>
                    <li v-for="error in errors" :key="error">{{ error }}</li>
                    </ul>
                </p>
                </div>
                <div class="col-md-12 cfield">
                    <button id="submit" name="submit" type="submit" value="Send" class="btn btn-hover iq-button">
                        <span class="iq-btn-text-holder">Send Message</span>
                        <span class="iq-btn-icon-holder">
                            <i aria-hidden="true" class="ion ion-plus"></i>
                        </span>
                        <br>
                    </button>
                </div>
                </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>

import emailjs from 'emailjs-com'

export default {
  name: 'ContactForm',
  props: {
    contactUsData: Object
  },
  data () {
    return {
      name: '',
      phone: '',
      email: '',
      message: '',
      errors: []
    }
  },
  methods: {
    sendEmail (e) {
      this.errors = []
      if (!this.name) this.errors.push('Name required.')
      if (!this.email) this.errors.push('Email required.')
      if (!this.message) this.errors.push('Message required.')
      if (this.name && this.email) {
        try {
          emailjs.sendForm('service_888e61q', 'template_vusn5yc', e.target, 'user_pOxKObRIUjM12T65F08qW')
        } catch (error) {
          console.log({ error })
        }
        // Reset form field
        this.name = ''
        this.email = ''
        this.phone = ''
        this.message = ''
      }
    }
  }
}
</script>
