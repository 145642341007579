<template>
 
  <main id="main" class="site-main">
    <college-sports />
	<div class="container">
		<div class="row">
			<div class="col-lg-12 col-sm-12">
				<contact-form />
			</div>
		</div>
	</div>
  </main>
</template>
<script>

import CollegeSports from './Components/Projects/College-sports.vue'
import ContactForm from './Contact/ContactFormLac.vue'

export default {
  name: 'ncl-lacrosse',
  components: {
    CollegeSports,
	ContactForm
  }
}
</script>
