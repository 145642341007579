<template>
<div class="container" >
	<div class="row about-us-row">
		<div class="col-sm-12 col-lg-12">
               <div class=" text-left iq-title-box iq-title-default">
				    <h1 class="iq-title">Leading the Collegiate Lacrosse Revolution</h1>
					<p> Next Level Sports will revolutionize the collegiate lacrosse world. </p>
					<p> We’re proud to announce the launch of the <strong> Next Collegiate League (NCL) </strong> “Olympic Style Lacrosse”—a faster-paced, higher-scoring lacrosse evolution. </p>
					<p> The Collegiate League will feature teams from the Mid-Atlantic region, including several Historically Black Colleges and Universities (HBCUs). These teams will feature student athletes playing the new “Olympic style” version of lacrosse that is being proposed for the 2028 Olympic games in Los Angeles. </p>
			   </div>
		</div>
	</div>
	<div class="row about-us-row">
	 <div class="col-sm-12 col-lg-12">
               <img width="100%" src="../../../../assets/images/NCL_Header_Graphic_3.jpg" class="attachment-large size-large" alt="" loading="lazy" >
         </div>
	</div>
	<div class="row about-us-row">
		<div class="col-sm-12 col-lg-12">
               <div class=" text-left iq-title-box iq-title-default">
               <h2 class="iq-title">Olympic Style Lacrosse – Innovation and Evolution</h2>
				<p> The Collegiate League will play what is known as Olympic Style Lacrosse—an evolved version of the sport that is faster-paced and higher-scoring. The term Olympic Style comes from the fact that the International Olympic Committee is considering adding this version to the 2028 Olympics in Los Angeles. .</p>
				<p> Olympic Style Lacrosse has offense similar to basketball (e.g., pick and rolls), so it is more attractive to younger players. This evolved version also requires a smaller footprint for play; for example, Olympic Style Lacrosse can be played on a basketball court-sized area.</p>
				<p> This version of the sport is newly emerging and gives athletes a chance to hone their skills and potentially compete at the Olympic level. Additionally, the smaller team size and lower start-up costs will allow Olympic Style Lacrosse to grow and thrive in underserved markets and at smaller colleges and HBCUs.</p>
            </div>
         </div>
	</div>
      <div class="row about-us-row">
         <div class="col-sm-12 col-lg-8">
            <div class="text-left iq-title-box iq-title-default">
               <h2 class="iq-title">Collegiate League Season and Coverage</h2>
				 <p>The Collegiate League will begin play with teams competing in The Next Level Sports Crown Classic tournament scheduled for late-March 2022. </p>
				 <p> Following the Crown Classic, the teams will compete in a 10-game season in April and May 2022. These games will be produced and broadcast on Next Level Sports, giving the players exposure to 20+ million U.S. households and 100+ million global homes. </p>
				<p> At the completion of the regular season, the top four teams will compete in a playoff format for a cash prize. </p>
				 <p> All players will be provided with equipment and apparel courtesy of Cascade Maverik Lacrosse, the leading equipment manufacturer in the nation. </p>
            </div>
         </div>
		 <div class="col-sm-12 col-lg-4">
               <img width="100%" src="../../../../assets/images/CrownClassicLogo.jpg" class="attachment-large size-large" alt="" loading="lazy" >
         </div>
      </div>
   </div>
</template>
<script>
	
export default {
  name: 'Collegesports'
}

</script>
